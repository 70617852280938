/* Split the screen in half */
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 2;
  top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  color: $app-text;
  background-color: #303030;
}

.scorebutton {
  height: 50%;
  width: 50%;
  position: fixed;
  z-index: 99;
  padding-top: 20px;
  background-color: rgba(255,255,255,0);
  border: none;
  outline: none;
}

.scorebutton:active {
  background-color: rgba(25,25,25,0.2);
}

button.add {
  top: 0;
}

button.minus {
  bottom: 0;
}

/* Control the left side */
.p1 {
  left: 0;
  border-right-style: solid;
  border-right-color: #262626;
}

/* Control the right side */
.p2 {
  right: 0;
  border-left-style: solid;
  border-left-color: #262626;
}

/* If you want the content centered horizontally and vertically */
.centeredScore {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 33vw;
  font-family: $score-font;
}

.playerName {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 10%;
  left: 50%;
  font-size: 5vw;
  color: $app-text;
  text-align: center;
  white-space: nowrap;
}
