.footer {
  position: absolute;
  width: 10em;
  padding: 1em;
  font-size: 3vw;
  color: $app-text;
  background-color: $modal-background;
  left: 35%;
  bottom: 2em;
  text-align: center;
  z-index: 10;
  font-family: $app-font;
}

.footer:focus {
  outline: 0;
}

.reset {
  padding: 0.59em;
}
