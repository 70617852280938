.racecontrol {
  padding-bottom: 2%;
}

.ui.circular.button {
  vertical-align: middle;
}

input {
  margin: 0.5vh;
  font-size: 300%;
  font-family: $app-font;
  text-align: center;
}

input[type=text] {
  width: 90%;
  padding: 5px 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

.playerInputSplit {
  width: 50%;
  height: 10vh;
}

#player1 {
  left: 0;
  float: left;
}

#player2 {
  right: 0;
  float: right;
}

#streamDescriptionContainer > .input {
  padding: 1px;
  width: 100%;
  margin-bottom: 10px;
}
