.streamingToggle {
  display: inline-block;
  width: 40%;
}

.saveConfigButton {
  margin-top: 3em;
}

.settingLabel {
  display: inline-block;
  text-align: left;
  line-height: 100%;
  width: 50%;
}

.settingInput {
  width: 40%;
}

.toggleButton {
  width: 7vw;
}

.saveButton {
  background-color: red;
}