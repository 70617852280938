.playerCard {
  position: relative;
  height: 100%;
  width: 100%;
}

.playerInfo {
  height: 100%;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  background-color: #fff;
  border-radius: 10px;
}

.playerAvatar {
  position: absolute;
  top: 10%;
  left: 5%;
  height: 80%;
}

.playerCardName {
  position: absolute;
  left: 35%;
  top: 12%;
  font-size: 4vh;
  line-height: 1.15;
}

.playerCardElo {
  position: absolute;
  left: 35%;
  top: 60%;
  font-size: 2vh
}

.clearPlayerIcon {
  position: absolute;
  top: 25%;
  right: 20%;
  height: 50%;
}