* {
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: none;
  touch-action: manipulation;
  font-family: $app-font;
  // cursor: none;
}

h1 {
  padding-top: 5vh;
  font-size: 8vh;
}

h2 {
  font-size: 6vh;
}

h1, h2 {
  color: white;
  font-family: $app-font;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.linkButton {
  min-width: 10%;
}

button {
  margin-top: 0;
  border: none;
  padding: 20px 20px;
  text-decoration: none;
  font-size: 6vh;
  font-family:$app-font;
}

button:focus {
  outline: 0;
}

input {
  border-radius: 10px;
  width: 80%;
}

.page {
  position: fixed;
  display: block;
  width: 100%;
  height:100%;
  background-color: $regular-background;
  z-index: 3;
}

.app {
  font-family: $app-font;
}

.page::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px; // Adjust the width as needed
  height: 32px; // Maintain aspect ratio
  background-image: url('https://storage.googleapis.com/releases-evosports/static/evosports-logo-landscape.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 50;
}

.logo {
  position: fixed;
  right: 16px;
  bottom: 0;
  width: 150px; // adjust as needed
  height: 150px; // adjust as needed
  background-image: url('https://storage.googleapis.com/releases-evosports/static/evosports-logo-white-square.png'); // replace with your logo path
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 50;
}
